'use strict';

angular.module("aerosApp")
	.factory('uploadLogoModal', [ "$uibModal", function ($uibModal) {
		function open(contact) {
			var modal = $uibModal.open({
                size: "md",
				templateUrl: "/static/templates/org/modal/uploadLogoModal.html",
				resolve: {
					contact: function () { return contact; }
				},
				controller: "uploadLogoModalCtrl"
			});
			return modal.result;
		}
		return {
			open: open
		}
	}])
	.controller("uploadLogoModalCtrl", ["$scope", "$uibModalInstance", "contact", "tinyImg", function($scope, $uibModalInstance, contact, tinyImg){
  	  $scope.tinyImg = tinyImg;
	  $scope.contact = contact;
	  $scope.file = null;
	  $scope.$watch(
		  function() { return $('#fileSelector').val(); },
		  function(newValue, oldValue) {
			  if (!newValue || $('#fileSelector').length === 0 || $('#fileSelector')[0].files.length === 0) return;
			  var fileData = $('#fileSelector')[0].files[0];
			  var reader = new FileReader();
			  reader.onload = function (e) {
				  $('#temp-img').attr({'src': e.target.result});
			  };
			  reader.readAsDataURL(fileData);
		  }
	  );

	  $scope.uploadLogo = function () {
		  if (!$scope.file) return;
		  $uibModalInstance.close($scope.file);
	  };
	  $scope.deleteLogo = function () {
		if ( confirm("Are you sure you want to delete the logo for " + contact.companyName + "?") ) {
			$uibModalInstance.close("delete");		    			
		}
	  };
  }]);